var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "multilevel" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, stripe: "", height: _vm.height - 46 }
        },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "所属车队",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cph",
              label: "车牌号",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "licensePlateColor",
              label: "车牌颜色",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm.colorObj[Number(scope.row.licensePlateColor)])
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: "车辆类型",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(_vm.typeObj[Number(scope.row.type)]))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "onlineTimeBetween",
              label: "时间段",
              "show-overflow-tooltip": true,
              width: "360"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "runMile",
              label: "行驶里程",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "timeLocateNum", label: "定位条数" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.pageObj.pageSize,
              "current-page": _vm.pageObj.currentPage
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }