<template>
  <div class="vechile-alarm" ref="vechileAlarm">
    <el-tabs type="border-card" @tab-click="clickTab" v-model="form.type">
      <el-tab-pane label="统计" name="1">
        <div class="form-area" ref="form">
          <analysis-form
            @getFormVal="onSubmit"
            :formatDay="true"
            :showCar="false"
            :chooseToday="true"
          >
            <el-button
              type="primary"
              size="small"
              :disabled="comArr.length === 0"
              :loading="downLoadStatus"
              @click="exportData(1)"
            >导出</el-button>
            
          </analysis-form>
        </div>
        <!-- 公司统计 -->
        <company-table ref="pagination1" :height="tableHeight - 40" :total="total" :tableData="comArr" />
      </el-tab-pane>      
      <el-tab-pane label="车辆" name="2">
        <div class="form-area" ref="form">
          <analysis-form :requireCompany="true" :chooseToday="true" @getFormVal="onSubmitOther">
            <el-button
              type="primary"
              size="small"
              :disabled="otherArr.length === 0"
              :loading="downLoadStatus"
              @click="exportData(2)"
            >导出</el-button>
          </analysis-form>
        </div>
        <other-table
        ref="pagination3"
          :height="tableHeight + 40"
          :total="total"
          :tableData="otherArr"
          @sendInfo="getOtherPageInfo"
        ></other-table>
      </el-tab-pane>
      <el-tab-pane label="详情" name="3">
        <div class="form-area">
          <analysis-form
            @getFormVal="onSearch">
            <el-button
              type="primary"
              size="small"
              :disabled="tableData.length === 0"
              :loading="downLoadStatus"
              @click="exportData(3)"
            >导出</el-button>
          </analysis-form>
        </div>
        <!-- 现有业务逻辑页面-->
        <car-table
          ref="pagination2"
          :height="tableHeight"
          :total="total"
          :tableData="tableData"
          :typeObj="modelObj"
          :colorObj="typeObj"
          @sendInfo="getPageInfo"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import analysisForm from "@/components/analysisForm/analysisFormNew.vue";
import companyTable from "./components/companyTable.vue";
import carTable from "./components/carTable.vue";
import otherTable from "./components/otherTable.vue";

import { queryIntervalForm, queryOnlineCompanyStatisticsAPI,queryVehicleStatisticsAPI } from "@/api/lib/gps-api.js";
import { queryDictsByCodes } from "@/api/lib/api.js";

import { addExcelExport } from "@/api/lib/refreshAlarm.js";

import { formatDict, getCurent, formatDate } from "@/common/utils/index";
export default {
  name: "vechileAlarm",
  components: {
    analysisForm,
    companyTable,
    carTable,
    otherTable
  },
  data () {
    return {
      tableHeight: 0,
      form: {
        type: "1", 
        vehicleNos:[],       
        companyId: null,
        beginTime: null,
        endTime: null,
        currentPage: 1,
        pageSize: 10,        
      },
      otherForm:{
        currentPage: 1,
        pageSize: 10,
        companyId: [],
        beginTime: null,
        endTime: null,
        vehicleNos: [],
      },
      tableData: [], //   车辆明细
      comArr: [],
      otherArr:[],
      total: 0,
      modelObj: null,
      typeObj: null,
      exportForm: {},
      downLoadStatus: false,
      mistakeList: [],
    };
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.vechileAlarm.clientHeight;
      const formHeight = this.$refs.form.clientHeight || 64;
      this.tableHeight = wholeHeight - formHeight - 40 - 30 - 10 - 40;
    },
    //导出
    exportData (key) {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      const queryData = [
        {
          name:"车辆在线公司统计",
          queryPath:"/vehicleCheckOnlineRate/onlineCompanyStatisticsExport",
        },
        {
          name:"车辆在线率统计",
          queryPath:"/vehicleCheckOnlineRate/onlineVehicleStatisticsExport",
        },
        {
          name:"车辆在线率明细",
          queryPath:"/vehicleStatistics/onlineExport",
        },
      ];
      let data = {
        baseUrl: "report",
        userId: sessionStorage.getItem("userId") || localStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName:`${queryData[key-1].name}-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath:queryData[key-1].queryPath,
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 提交
    onSubmit (value) {
      this.form.currentPage = 1;      
      this.form.companyId = value.companyId;
      this.form.beginTime = value.beginTime;
      this.form.endTime = value.endTime;
      this.form.alarmSource = value.alarmSource;
      this.getDataList();
    },
    onSubmitOther(value){
      this.otherForm.currentPage = 1;
      this.otherForm.companyId = value.companyId;
      this.otherForm.beginTime = value.beginTime;
      this.otherForm.endTime = value.endTime;
      this.otherForm.vehicleNos = value.vehicleNos;
      this.getDataList();
    },
    // 点击查询
    onSearch (value) {
      this.form.vehicleNos = value.vehicleNos;
      this.form.companyId = value.companyId;
      this.form.beginTime = value.beginTime;
      this.form.endTime = value.endTime;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.getDataList();
    },
    // 获取列表
    getDataList () {
      let data = { ...this.form };
      let otherData = { ...this.otherForm };
      delete data.type;
      switch (this.form.type) {
        case "3":
          /**获取详情*/
          queryIntervalForm(data).then((res) => {
            if (res.code === 1000) {
              this.tableData = res.data.list;
              this.total = res.data.total;
            } else {
              this.tableData = [];
              this.total = 0;
            }
          });
          this.exportForm = { ...data };
          break;
        case "2":
          /**车辆在线统计 */
          queryVehicleStatisticsAPI(otherData).then(res=>{
            if (res.code === 1000) {
              this.otherArr = res.data.list;
              this.total = res.data.total;
            } else {
              this.otherArr = [];
              this.total = 0;
            }
          })
          this.exportForm = { ...otherData };
          break;
        case "1":
          /**获取企业统计*/
          delete data.vehicleNos;
          queryOnlineCompanyStatisticsAPI(data).then((res) => {
            if (res.code === 1000 && res.data) {
              this.comArr = [res.data];
              this.total = 1;
            } else {
              this.comArr = [];
              this.total = 0;
            }
          });
          this.exportForm = { ...data };
          break;
      }      
    },
    /**切换统计|详情 */
    clickTab () {
      this.total = 0;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.otherForm.currentPage = 1;
      this.otherForm.pageSize = 10;
      this.comArr = [];
      this.tableData = [];
      this.otherArr = [];
    },
    /**分页参数切换 */
    getPageInfo (params) {
      this.form.currentPage = params.currentPage;
      this.form.pageSize = params.pageSize;
      if (this.tableData.length === 0) return;
      this.getDataList();
    },
    getOtherPageInfo(params){
      this.otherForm.currentPage = params.currentPage;
      this.otherForm.pageSize = params.pageSize;
      if (this.otherArr.length === 0) return;
      this.getDataList();
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: "VETY,CPYS,PCWB" }).then((res) => {
        if (res.code === 1000) {
          this.mistakeList = res.data.PCWB;
          this.modelObj = formatDict(res.data.VETY);
          this.typeObj = formatDict(res.data.CPYS);
        }
      });
    },
  },
  created () {
    this.getDicts();
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.vechile-alarm {
  height: 100%;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  .el-tabs {
    height: 100%;
    .el-tabs__content {
      height: calc(100% - 40px);
    }
  }
}
</style>
