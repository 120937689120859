<template>
  <div class="multilevel">
    <el-table :data="tableData" stripe :height="height" style="width: 100%">
      <el-table-column type="index" width="70" label="序号"></el-table-column>
      <el-table-column prop="statisticsDate" label="日期"></el-table-column>
      <el-table-column
        prop="companyName"
        label="公司"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="timeSlotAmount" label="总时段数"></el-table-column>
      <el-table-column
        prop="vehicleCount"
        label="上线车辆总数"
      ></el-table-column>
      <el-table-column
        prop="runMile"
        label="行驶里程数"
      ></el-table-column>
      <el-table-column
        prop="positionCount"
        label="定位条数"
      ></el-table-column>
      <el-table-column prop="onlineRate" label="在线率">
        <template slot-scope="scope">
          <span>{{
            computedRate(scope.row.onlineRate)
          }}</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageObj.pageSize"
        :current-page="pageObj.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number
    },
    tableData: {
      type: Array
    },
    total: {
      type: Number
    }
  },
  watch: {},

  data () {
    return {
      pageObj: {
        currentPage: 1,
        pageSize: 10
      }
    }
  },

  methods: {
    computedRate(val){
      if(val){
       return val * 100 + '%'
      }else{
        return "-"
      }
    },
    handleSizeChange (val) {
      this.pageObj.pageSize = val
    },
    handleCurrentChange (val) {
      this.pageObj.currentPage = val
    },
    reset () {
      this.pageObj.currentPage = 1
      this.pageObj.pageSize = 10
    }
  }
}
</script>

<style>
</style>
