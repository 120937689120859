<template>
  <div class="multilevel">
    <el-table :data="tableData" stripe :height="height - 46" style="width: 100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属车队"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="licensePlateColor"
        label="车牌颜色"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
          colorObj[Number(scope.row.licensePlateColor)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="type"
        label="车辆类型"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
          typeObj[Number(scope.row.type)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="onlineTimeBetween"
        label="时间段"
        :show-overflow-tooltip="true"
        width="360"
      ></el-table-column>
      <el-table-column
        prop="runMile"
        label="行驶里程"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="timeLocateNum" label="定位条数"></el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageObj.pageSize"
        :current-page="pageObj.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number
    },
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    total: {
      type: Number
    },
    typeObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    colorObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {},

  data() {
    return {
      pageObj: {
        currentPage: 1,
        pageSize: 10
      }
    }
  },

  methods: {
    handleSizeChange(val) {
      this.pageObj.pageSize = val
      this.$emit('sendInfo', this.pageObj)
    },
    handleCurrentChange(val) {
      this.pageObj.currentPage = val
      this.$emit('sendInfo', this.pageObj)
    },
    reset() {
      this.pageObj.currentPage = 1
      this.pageObj.pageSize = 10
    }
  }
}
</script>

<style lang="scss" scoped>
.color-yellow{
  color:gold;
}
.color-red{
  color:#F74242;
}
</style>
